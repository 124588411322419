<template>
  <div class="price-container">
    <div class="price-over-title">
      <small>Super Rent</small>
    </div>
    <div class="price-title">
      White Label PWA zur digitalen Fahrzeugvermietung <br>
      €14.90 netto monatlich pro Fahrzeug ohne Diebstahlversicherung <br>
      €19.90 netto monatlich pro Fahrzeug inkl. Diebstahlversicherung <br> monatlich kündbar
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="3" class="price-benefits-container">
        <div class="price-benefits-image-container">
          <v-img class="price-benefits-image" src="../../../public/images/icons/Nutzerapp.svg"/>
        </div>
        <div>
          Nutzer-App
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="price-benefits-container">
        <div class="price-benefits-image-container">
          <v-img class="price-benefits-image" src="../../../public/images/icons/Flottenmanagement.svg"/>
        </div>
        <div>
          Flottenmanagement
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="price-benefits-container">
        <div class="price-benefits-image-container">
          <v-img class="price-benefits-image" src="../../../public/images/icons/Tracking.svg"/>
        </div>
        <div>
          Tracking & Analyse
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="price-benefits-container">
        <div class="price-benefits-image-container">
          <v-img class="price-benefits-image" src="../../../public/images/icons/Security.svg"/>
        </div>
        <div>
          100 % Security
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "Price",
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/variables.scss";
  @import "../../assets/scss/layout.scss";
  .price-container {
    background: #F1F2F7 0 0 no-repeat padding-box;
    border-radius: 30px;
    padding: 50px;
    @include MQ(XS) {
      padding: 5px;
    }
    margin-top: 45px;
    color: $secondary-text;
    .price-over-title {
      text-align: center;
      margin-bottom: 10px;
      font-weight: normal;
    }
    .price-title {
      text-align: center;
      font-size: 35px;
      line-height: 38px;
      font-weight: normal;
      margin-bottom: 80px;
      @include MQ(XS) {
        font-size: 25px;
        line-height: 28px;
      }
    }
    .price-benefits-container {
      color: black;
      font-weight: normal;
      text-align: center;
      .price-benefits-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        .price-benefits-image {
          width: 50px;
          max-width: 50px;
          display: flex;
        }
      }
    }
  }
</style>
